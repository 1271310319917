import { Grant, Grants, Privilege } from '@spec/Grants';
import dayjs from 'dayjs';
import { StrictMap } from '../lib/MapUtils';

export const privilegeLabelMap: StrictMap<Privilege, string> = new StrictMap([
    ['EDIT_TALENT', '従業員情報の編集'],
    ['READ_LOGS', '操作ログ閲覧'],
    ['SHOW_LEAVED_TALENT', '退職者閲覧'],
    ['SHOW_FULL_PERSONALITY', 'プライバシー情報閲覧'],
    ['GRANT_PRIVILEGE', '権限の付与'],
    ['EDIT_WORKPLACES', '勤務地の編集'],
    ['MANAGE_CIRCLE', 'サークル管理'],
    ['MANAGE_VALUE_FEEDBACK', 'バリューフィードバック管理'],
    ['UPLOAD_PROFILE_IMAGE', 'プロフィール画像設定'],
    ['MANAGE_TODO', 'やること管理'],
    ['DOWNLOAD_CSV', 'CSVダウンロード'],
    ['MANAGE_SURVEY', 'サーベイ管理'],
    ['MANAGE_DIVE', 'DIVE管理'],
    ['SHOW_HR_DASHBOARD', 'HRダッシュボード閲覧'],
]);

export const hasAnyPrivilege = (grants: Grants): boolean => {
    for (const value of Object.values(grants)) {
        if (value === true) {
            return true;
        }
        if (Array.isArray(value) && value.length > 0) {
            return true;
        }
    }
    return false;
};

export const grantsToTexts = (grants: Grants): string[] =>
    [
        grants.readLogs && privilegeLabelMap.get('READ_LOGS'),
        grants.showLeavedTalent && privilegeLabelMap.get('SHOW_LEAVED_TALENT'),
        grants.showFullPersonality && privilegeLabelMap.get('SHOW_FULL_PERSONALITY'),
        grants.grantPrivilege && privilegeLabelMap.get('GRANT_PRIVILEGE'),
        grants.editWorkplaces && privilegeLabelMap.get('EDIT_WORKPLACES'),
        grants.manageCircle && privilegeLabelMap.get('MANAGE_CIRCLE'),
        grants.manageValueFeedback && privilegeLabelMap.get('MANAGE_VALUE_FEEDBACK'),
        grants.uploadProfileImage && privilegeLabelMap.get('UPLOAD_PROFILE_IMAGE'),
        grants.manageTodo && privilegeLabelMap.get('MANAGE_TODO'),
        grants.downloadCsv && privilegeLabelMap.get('DOWNLOAD_CSV'),
        grants.manageSurvey && privilegeLabelMap.get('MANAGE_SURVEY'),
        grants.manageDive && privilegeLabelMap.get('MANAGE_DIVE'),
        grants.showHrDashboard && privilegeLabelMap.get('SHOW_HR_DASHBOARD'),
    ].filter((v): v is string => v !== false);

type GrantSorter = (a: Grant, b: Grant) => -1 | 0 | 1;

export const sortGrants: GrantSorter = (a, b) =>
    sortGrantsByGrantedAtDesc(a, b) || sortGrantsByTalentId(a, b) || sortGrantsByPrivilege(a, b);

const sortGrantsByGrantedAtDesc: GrantSorter = (_a, _b) => {
    const a = dayjs(_a.grantedAt);
    const b = dayjs(_b.grantedAt);
    if (a < b) {
        return 1;
    }
    if (a > b) {
        return -1;
    }
    return 0;
};

const sortGrantsByTalentId: GrantSorter = (_a, _b) => {
    const a = _a.talentId;
    const b = _b.talentId;
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};

const sortGrantsByPrivilege: GrantSorter = (_a, _b) => {
    const a = _a.privilege;
    const b = _b.privilege;
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};
