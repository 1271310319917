import type { Team } from '@spec/Organization';
import type { Talent, TalentWithPrivacy } from '@spec/Talent';
import dayjs from 'dayjs';
import { findChildTeams } from './Organization';
import { getTeamMembers } from './Talent';

export const calcAverageTenure = (talents: Talent[], currentTime: Date): number => {
    let total = 0;
    for (const talent of talents) {
        const diffMonth = dayjs(currentTime).diff(talent.joinedAt, 'month');
        total += diffMonth;
    }
    return total / talents.length;
};

export const calcAverageAge = (talents: TalentWithPrivacy[], currentTime: Date): number => {
    const ages: number[] = [];
    for (const talent of talents) {
        if (talent.birthday !== null) {
            const age = dayjs(currentTime).diff(talent.birthday, 'year');
            ages.push(age);
        }
    }
    return ages.reduce((acc, val) => acc + val, 0) / ages.length;
};

export const calcFemaleRatio = (talents: TalentWithPrivacy[]): number => {
    return talents.filter((v) => v.sex === 2).length / talents.length;
};

export const isPermanentEmployee = (talent: Talent) =>
    talent.employment.employmentStatus === '正社員';

export const isManager = (talent: Talent, talents: Talent[], teams: Team[]) => {
    if (talent.grade !== null && talent.grade.level >= 4) {
        return true;
    }
    const titles = ['取締役', '執行役員', '董事長'];
    if (titles.some((title) => talent.position.includes(title))) {
        return true;
    }
    if (talent.position === '') {
        return false;
    }
    const children = findChildTeams(talent.teamId, teams);
    if (children.length >= 3) {
        // children contains the team itself,
        // so the team has at least 2 children.
        return true;
    }
    const members = children
        .map((v) => getTeamMembers(talents, v.id, true).length)
        .reduce((acc, val) => acc + val, 0);
    if (members >= 10) {
        return true;
    }
    return false;
};

export const isSkilledTalent = (talent: Talent) => talent.grade !== null && talent.grade.level >= 3;
