import { stringify } from 'csv-stringify/browser/esm/sync';

export const generateCsv = (fields: string[], data: (string | number | null)[][]): string => {
    const body = stringify([fields, ...data], { record_delimiter: '\r\n', eof: false });
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, body], { type: 'text/csv' });
    return window.URL.createObjectURL(blob);
};

export const removeCsvUrl = (csvUrl: string): void => window.URL.revokeObjectURL(csvUrl);
