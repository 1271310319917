import { Box, BoxProps } from '@mui/material';
import { forwardRef } from 'react';

export const FlexBox = forwardRef<unknown, BoxProps>((props, ref) => {
    const boxProps: BoxProps = {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    };
    Object.assign(boxProps, props);
    return <Box {...boxProps} ref={ref} />;
});
