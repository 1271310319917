import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableHead,
    TableRow,
    TableSortLabel,
    type TableProps,
} from '@mui/material';
import React, { Fragment, useCallback, useState, type ReactNode } from 'react';
import { ContentCopyIcon } from './Icons';
import { Pager, sliceItems, type PagerProps } from './Pager';

export type SortDirection = 'asc' | 'desc';

export const comparer = <T,>(
    a: T,
    b: T,
    key: keyof T,
    secondKey: keyof T,
    direction: SortDirection
): number => {
    const order = direction === 'asc' ? 1 : -1;
    const aa = a[key];
    const bb = b[key];
    if (aa === bb) {
        if (key === secondKey) {
            return 0;
        }
        return comparer(a, b, secondKey, secondKey, direction);
    }
    return aa > bb ? order : order * -1;
};

export const useTableSorter = <T,>(defaultKey: keyof T, defaultDirection?: SortDirection) => {
    const [sortKey, setSortKey] = useState(defaultKey);
    const [direction, setDirection] = useState<SortDirection>(defaultDirection ?? 'asc');
    const onSort = useCallback(
        (k: keyof T) => {
            if (sortKey === k) {
                setDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
            } else {
                setSortKey(k);
                setDirection('asc');
            }
        },
        [sortKey]
    );
    const SortHeader: React.FC<TableCellProps & { sortKey: keyof T }> = (props) => {
        const { sortKey: x, children, ...rest } = props;
        return (
            <TableCell {...rest}>
                <TableSortLabel
                    active={sortKey === x}
                    direction={direction}
                    onClick={() => onSort(x)}
                >
                    {children}
                </TableSortLabel>
            </TableCell>
        );
    };
    return { sortKey, direction, SortHeader, onSort };
};

type SortableTableRow = Record<string, any> & { id: string | number };

type SortableTableProps<T> = {
    headers: ReadonlyArray<[keyof T, string]>;
    defaultSortKey: keyof T;
    defaultSortDirection: SortDirection;
    secondarySortKey: keyof T;
    rows: ReadonlyArray<T>;
    children: (row: T) => ReactNode;
    pagerProps?: PagerProps;
} & Omit<TableProps, 'children'>;

export const SortableTable = <T extends SortableTableRow>(props: SortableTableProps<T>) => {
    const {
        headers,
        defaultSortKey,
        defaultSortDirection,
        secondarySortKey,
        rows,
        content,
        children,
        pagerProps,
        ...rest
    } = props;
    const { sortKey, direction, SortHeader } = useTableSorter<T>(
        defaultSortKey,
        defaultSortDirection
    );
    const sortedRows = rows.toSorted((a, b) =>
        comparer(a, b, sortKey, secondarySortKey, direction)
    );
    const pager = pagerProps ? <Pager {...pagerProps} /> : null;
    const slicedRows = pagerProps
        ? sliceItems(sortedRows, pagerProps.current, pagerProps.perItems)
        : sortedRows;
    return (
        <>
            {pager}
            <Table {...rest}>
                <TableHead>
                    <TableRow>
                        {headers.map(([key, label]) => (
                            <SortHeader key={String(key)} sortKey={key}>
                                {label}
                            </SortHeader>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {slicedRows.map((row) => (
                        <Fragment key={row.id}>{children(row)}</Fragment>
                    ))}
                </TableBody>
            </Table>
            {pager}
        </>
    );
};

export const SortableTableWithCopyButton = <T extends SortableTableRow>(
    props: SortableTableProps<T> & {
        serializeRow: (row: T) => Array<string | number>;
    }
) => {
    const { serializeRow, ...rest } = props;
    return (
        <Box>
            <SortableTable {...rest} />
            <Box textAlign="right" mt={1}>
                <TableCopyButton
                    headers={props.headers}
                    rows={props.rows.toSorted((a, b) =>
                        comparer(
                            a,
                            b,
                            props.defaultSortKey,
                            props.secondarySortKey,
                            props.defaultSortDirection
                        )
                    )}
                    serializeRow={props.serializeRow}
                />
            </Box>
        </Box>
    );
};

const TableCopyButton = <T extends SortableTableRow>(props: {
    headers: ReadonlyArray<[keyof T, string]>;
    rows: ReadonlyArray<T>;
    serializeRow: (row: T) => Array<string | number>;
}) => {
    const [copied, setCopied] = useState(false);
    const handleClick = useCallback(() => {
        const content = [props.headers.map((v) => v[1]), ...props.rows.map(props.serializeRow)]
            .map((v) => v.join('\t'))
            .join('\n');
        return navigator.clipboard
            .writeText(content)
            .then(() => setCopied(true))
            .then(() =>
                setTimeout(() => {
                    setCopied(false);
                }, 800)
            );
    }, [props.headers, props.rows, props.serializeRow]);
    return (
        <Button
            variant="outlined"
            sx={{
                p: 0.5,
                px: 1.5,
                width: '9rem',
            }}
            onClick={handleClick}
            startIcon={<ContentCopyIcon fontSize="small" color="primary" />}
            disabled={copied}
        >
            <Box flexGrow={1}>{copied ? 'コピーしました' : 'テーブルをコピー'}</Box>
        </Button>
    );
};
