import { Env } from './Contract';

export const env: Env = {
    apiHost: 'https://api.hitonowa.work',
    apiEncryptionKey: 'DH3T3cAneaEqGNWEoMwGNb8f8LwmcdIi',
    sentryOptions: {
        environment: 'production',
        tracesSampleRate: 0.05,
    },
    firebaseOptions: {
        apiKey: 'AIzaSyDH7rpiGVg1CVFNqGnoehoH6JcwfztOOTg',
        authDomain: 'hitonowa.work',
        projectId: 'hitonowa-bb879',
        storageBucket: 'hitonowa-bb879.appspot.com',
        messagingSenderId: '843480685781',
        appId: '1:843480685781:web:dcf03678041d4cbc24965c',
        measurementId: 'G-JSFLSSSSV1',
    },
    enableRegister: false,
    wakeUpRequest: false,
    queryClientStaleSeconds: 30 * 60,
};
