import { createContext, ReactNode, useContext } from 'react';
import { hiraToKana, NO_GRADE_SHORT_TEXT } from '../../domains/Talent';
import {
    useForm,
    useFormBoolean,
    useFormDate,
    useFormNumber,
    useFormText,
    type Form,
} from '../../lib/Form';
import { required } from '../../lib/Form/Validators';
import { DialogContextProvider, useGenericDialogContext } from '../Context';
import { NOT_SELECTED_WORKPLACE } from '../WorkplaceSelector';

export type DialogMode = 'Confirm';
export const useDialogContext = useGenericDialogContext<DialogMode>;

export interface RegisterElements {
    email: string;
    employeeCode: string;
    joinedAt: Date | null;
    isNewGraduate: boolean;
    employmentStatus: string;
    gradeText: string;
    teamId: number | null;
    position: string;
    lastName: string;
    firstName: string;
    lastNameKana: string;
    firstNameKana: string;
    romanName: string;
    workplace: string;
}

const useRegisterForm = () =>
    useForm<RegisterElements>({
        email: useFormText(''),
        employeeCode: useFormText('', [required]),
        joinedAt: useFormDate(null, [required]),
        isNewGraduate: useFormBoolean(false),
        employmentStatus: useFormText('', [required]),
        gradeText: useFormText(NO_GRADE_SHORT_TEXT),
        teamId: useFormNumber(null, [required]),
        position: useFormText(''),
        lastName: useFormText('', [required]),
        firstName: useFormText('', [required]),
        lastNameKana: useFormText('', [required], { onCompositionEnd: [hiraToKana] }),
        firstNameKana: useFormText('', [required], { onCompositionEnd: [hiraToKana] }),
        romanName: useFormText('', [required]),
        workplace: useFormText(NOT_SELECTED_WORKPLACE),
    });
export type RegisterForm = Form<RegisterElements>;

const RegisterFormContext = createContext({} as { form: RegisterForm });
const RegisterFormContextProvider: React.FC<{ children: ReactNode }> = (props) => {
    const form = useRegisterForm();
    return (
        <RegisterFormContext.Provider value={{ form }}>
            {props.children}
        </RegisterFormContext.Provider>
    );
};
export const useRegisterFormContext = () => useContext(RegisterFormContext);

export const ContextProvider: React.FC<{
    children: ReactNode;
}> = (props) => {
    return (
        <DialogContextProvider>
            <RegisterFormContextProvider>{props.children}</RegisterFormContextProvider>
        </DialogContextProvider>
    );
};
