import { Box, Divider, Stack, Typography } from '@mui/material';
import { HelpAccordion } from '../HelpAccordion';

interface Column {
    title: string;
    description: string[];
}

const columns: Column[] = [
    {
        title: '従業員数',
        description: ['対象組織に在籍している人数。雇用形態は問わない。'],
    },
    {
        title: '正社員数',
        description: [
            '従業員数のうち、雇用形態が「正社員」の人数。雇用区分（社員番号の2文字目）「役員」は含まない。',
        ],
    },
    {
        title: '平均勤続年数',
        description: ['正社員の在籍期間を月数で算出したものの平均値。'],
    },
    { title: '平均年齢', description: ['正社員の年齢の平均値。'] },
    {
        title: '女性比率',
        description: [
            '正社員のうち性別が「女性」で登録されている人が占める割合。分母には「性別不明」も含む（社外役員など）。',
        ],
    },
    {
        title: '管理職',
        description: [
            '正社員のうち、以下のいずれかの条件に当てはまる人数。',
            '- グレード4以上',
            '- 役職に「取締役」「執行役員」「董事長」のいずれかを含む',
            '- 何らかの役職を持ち、所属組織が下位組織を2つ以上を持つ',
            '- 何らかの役職を持ち、所属組織以下に兼務を含めて10人以上（本人含む）が在籍している',
        ],
    },
    { title: '女性管理職比率', description: ['管理職全体に占める女性の割合。'] },
    { title: '高度人材', description: ['正社員のうちグレード3以上の人数。'] },
    { title: '高度女性人材比率', description: ['高度人材全体に占める女性の割合。'] },
];

export const ColumnsHelp = () => {
    return (
        <HelpAccordion title="人的資本データの項目について">
            <Stack spacing={2}>
                {columns.map((column) => (
                    <Box key={column.title}>
                        <Typography color="primary">{column.title}</Typography>
                        <Divider />
                        <Stack mt={1} spacing={2}>
                            {column.description.map((v, i) => (
                                <Typography key={i} variant="body2">
                                    {v}
                                </Typography>
                            ))}
                        </Stack>
                    </Box>
                ))}
            </Stack>
        </HelpAccordion>
    );
};
