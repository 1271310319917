import { amber, blue, blueGrey, grey, lightBlue } from '@mui/material/colors';
import { PaletteOptions } from '@mui/material/styles';

export type ColorMode = 'light' | 'dark';

export const lightPalette: PaletteOptions = {
    // from https://material-ui.com/style/color/
    primary: {
        dark: blueGrey[700],
        main: blueGrey[700],
        light: blueGrey[300],
        contrastText: '#fff',
    },
    secondary: {
        main: amber['A700'],
        contrastText: '#fff',
    },
    background: {
        paper: grey[50],
        default: '#fff',
    },
    text: {
        primary: '#000',
        secondary: blueGrey[700],
        disabled: grey[600],
    },
    link: blue[700],
    nav: {
        background: '#fce648',
        selected: amber['A200'],
        icon: amber[900],
        text: grey[900],
    },
};

export const darkPalette: PaletteOptions = {
    mode: 'dark',
    primary: {
        dark: '#19b9a2',
        main: '#19b9a2',
        light: grey['700'],
        contrastText: '#fff',
    },
    secondary: {
        main: amber['A700'],
        contrastText: '#fff',
    },
    background: {
        paper: '#333',
        default: grey[900],
    },
    text: {
        primary: '#fff',
        secondary: '#19b9a2',
        disabled: grey[500],
    },
    link: lightBlue['A200'],
    nav: {
        background: grey[800],
        icon: amber[800],
        text: '#fff',
        selected: grey[700],
    },
};

interface NavPalette {
    background: string;
    selected: string;
    icon: string;
    text: string;
}

export const colorThemes: Record<string, NavPalette> = {
    midi: {
        background: grey[800],
        icon: amber[800],
        text: '#fff',
        selected: grey[700],
    },
    frost: {
        background: '#57667b',
        selected: '#648fac',
        icon: '#ECF0F1',
        text: '#FFFFFF',
    },
    evergreen: {
        background: '#004200',
        selected: '#16A085',
        icon: '#F1C40F',
        text: '#FFFFFF',
    },
    sakura: {
        background: '#ffe8ef',
        selected: '#fffcfe',
        icon: '#fd71a0',
        text: '#212121',
    },
    mariaRosa: {
        background: '#e13f7d',
        selected: '#fb67b1',
        icon: '#FFD1DC',
        text: '#FFFFFF',
    },
    ocean: {
        background: '#1B4F72',
        selected: '#2874A6',
        icon: '#AED6F1',
        text: '#FFFFFF',
    },
    sunset: {
        background: '#bb5023',
        selected: '#E67E22',
        icon: '#faab4c',
        text: '#FFFFFF',
    },
};
