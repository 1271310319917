import { Button, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { FlexBox } from '../../FlexBox';
import { EmailIcon } from '../../Icons';

export const CopyableEmail: React.FC<{ email: string }> = (props) => {
    const [copied, setCopied] = useState(false);
    if (props.email === '') {
        return (
            <FlexBox>
                <EmailIcon color="disabled" fontSize="small" />
                <Typography ml={0.5} variant="body2" color="textDisabled">
                    未登録
                </Typography>
            </FlexBox>
        );
    }
    return (
        <Tooltip
            title={copied ? 'コピーしました' : 'メールアドレスをコピー'}
            arrow
            placement="top"
            onOpen={() => setCopied(false)}
            onClose={() =>
                // keep the message until the tooltip is faded out
                setTimeout(() => {
                    setCopied(false);
                }, 200)
            }
        >
            <Button
                sx={{ ml: -0.5 }}
                color="inherit"
                size="small"
                onClick={() =>
                    navigator.clipboard.writeText(props.email).then(() => setCopied(true))
                }
            >
                <EmailIcon color="disabled" fontSize="small" />
                <Typography ml={0.5} variant="body2">
                    {props.email}
                </Typography>
            </Button>
        </Tooltip>
    );
};
