import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, CircularProgress, TextField, Typography } from '@mui/material';
import { Team } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import { UseQueryResult } from '@tanstack/react-query';
import React, { useState } from 'react';
import { queryToArray } from '../../queries';
import { useTeamsContext } from '../Context';
import { getOptionLabel, isTalent, isTeam, searchItems, sortTalentsAndTeams } from './Domains';
import { ItemCard } from './ItemCard';

const COMPLETION_MIN_LENGTH = 2;

type Item = Talent | Team | string;

export const Search: React.FC<{
    maybeTalents: UseQueryResult<Talent[]>;
    value: Talent | Team | null;
    onChange: (v: Talent | Team | null) => void;
}> = (props) => {
    const talents = queryToArray(props.maybeTalents);
    const { availableTeams, teams } = useTeamsContext();
    const items: Item[] = sortTalentsAndTeams([...talents, ...availableTeams]);
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);
    const inProgress = props.maybeTalents.isPending;
    return (
        <Box>
            <Autocomplete
                fullWidth
                forcePopupIcon={false}
                options={items}
                getOptionLabel={(v) => getOptionLabel(v)}
                loading={inProgress}
                loadingText={
                    <Box textAlign="center">
                        <CircularProgress color="primary" size={20} />
                    </Box>
                }
                noOptionsText={
                    <Typography variant="body2" color="error">
                        候補が見つかりません
                    </Typography>
                }
                renderOption={(p, v) => (
                    <li {...p} key={typeof v === 'string' ? v : v.id}>
                        <ItemCard item={v} teams={teams} />
                    </li>
                )}
                getOptionDisabled={(v) => !isTalent(v) && !isTeam(v)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="人や組織を名前で検索"
                        slotProps={{
                            input: {
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        {inProgress ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            <SearchIcon />
                                        )}
                                        {params.InputProps.startAdornment}
                                    </>
                                ),
                            },
                        }}
                    />
                )}
                inputValue={inputValue}
                onInputChange={(event, value) => {
                    if (event?.type !== 'blur') {
                        setInputValue(value);
                    }
                }}
                open={open && inputValue.length >= COMPLETION_MIN_LENGTH}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onFocus={() => setInputValue('')}
                blurOnSelect={true}
                filterOptions={(options, state) =>
                    inProgress ? [] : searchItems(state.inputValue, options)
                }
                onChange={(_event, value) => {
                    if (isTalent(value)) {
                        props.onChange(talents.find((v) => v.id === value.id) ?? null);
                    } else if (isTeam(value)) {
                        props.onChange(availableTeams.find((v) => v.id === value.id) ?? null);
                    } else {
                        props.onChange(null);
                    }
                }}
            />
        </Box>
    );
};
