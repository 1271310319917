import { Box, Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { getNewcomersUrl } from '../../../Routes';
import { isEnrolledTalent, sortTalentsByJoinedAt } from '../../../domains/Talent';
import { usePinnedArticles } from '../../../queries/notes';
import { useTalentsContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { SubTitle } from '../../PageTitle';
import { RouterLink } from '../../RouterLink';
import { WaitQuery } from '../../WaitLoading';
import { NewcomerCard } from './NewcomerCard';

const DEFAULT_AMOUNT = 3;

export const NewcomerList: React.FC<{
    year: number;
    month: number;
    defaultOpen?: true;
}> = (props) => {
    const { talents } = useTalentsContext();
    const [open, setOpen] = useState(props.defaultOpen ?? false);
    const ym = dayjs(`${props.year}-${props.month}-1`);
    if (!ym.isValid()) {
        throw new Error(`${ym} is invalid year/month`);
    }
    const yearMonth = ym.format('YYYY-MM');
    const newcomers = talents
        .filter((v) => dayjs(v.joinedAt).format('YYYY-MM') === yearMonth)
        .filter((v) => isEnrolledTalent(v))
        .sort(sortTalentsByJoinedAt)
        .reverse();
    const sliced = open ? newcomers : newcomers.slice(0, DEFAULT_AMOUNT);
    const lastMonth = ym.subtract(1, 'month');

    const LinkToPrevious = (
        <Box ml={0.5}>
            <RouterLink
                variant="body2"
                to={getNewcomersUrl(lastMonth.year(), lastMonth.month() + 1)}
            >
                先月分を見る
            </RouterLink>
        </Box>
    );
    const Title = <SubTitle title={`${props.year}年${props.month}月に入社した人たち`} />;

    const maybePinnedArticles = usePinnedArticles();

    if (newcomers.length === 0) {
        return (
            <Box>
                {Title}
                <FlexBox mt={1}>
                    <Typography color="primary" variant="body2">
                        新しく登録された人はまだいません。
                    </Typography>
                    {LinkToPrevious}
                </FlexBox>
            </Box>
        );
    }
    return (
        <Box mt={1}>
            {Title}
            <FlexBox my={1}>
                <Typography color="primary" variant="body2">
                    {newcomers.length}名が新しく入社しています。
                </Typography>
                {LinkToPrevious}
            </FlexBox>
            <WaitQuery query={maybePinnedArticles}>
                {({ data }) =>
                    sliced.map((talent) => (
                        <NewcomerCard
                            key={talent.id}
                            talent={talent}
                            pinnedArticles={data.articles.filter((v) => v.talentId === talent.id)}
                        />
                    ))
                }
            </WaitQuery>
            {!open && newcomers.length > DEFAULT_AMOUNT && (
                <Button fullWidth variant="outlined" onClick={() => setOpen(true)}>
                    全員を見る
                </Button>
            )}
        </Box>
    );
};
