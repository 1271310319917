import CheckBoxIcon from '@mui/icons-material/Check';
import FilterAltIcon from '@mui/icons-material/FilterList';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Divider,
    FormControlLabel,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip,
    Typography,
} from '@mui/material';
import type { Team } from '@spec/Organization';
import {
    SurveyGroup,
    SurveyMemo,
    SurveyQuestion,
    type SurveyPeriod,
    type SurveyPeriodReportResponse,
} from '@spec/Survey';
import { TalentId, type Talent } from '@spec/Talent';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { findBaseTeam } from '../../../../domains/Organization';
import { isLikertQuestion } from '../../../../domains/Survey';
import { findTalent, fullName, isEnrolledTalent } from '../../../../domains/Talent';
import { findById, sortByKey } from '../../../../lib/ArrayUtils';
import { StateValue, useStateValue } from '../../../../lib/Context';
import { queryToArray } from '../../../../queries';
import { useMeContext } from '../../../../queries/me';
import { useSurveyMemos, useSurveyPeriodReport } from '../../../../queries/survey';
import { useCurrentTimeContext, useTalentsContext, useTeamsContext } from '../../../Context';
import { FlexBox } from '../../../FlexBox';
import { ContentCopyIcon, ExpandMoreIcon } from '../../../Icons';
import { Markdown } from '../../../Markdown';
import { NoItems } from '../../../NoItems';
import { Pager, sliceItems } from '../../../Pager';
import { PopoverTalent } from '../../../PopoverTalent';
import { RouterLink } from '../../../RouterLink';
import { comparer, useTableSorter, type SortDirection } from '../../../SortableTable';
import { TalentAvatar } from '../../../TalentAvatar';
import { WaitLoading } from '../../../WaitLoading';
import { TalentSearch } from '../../../search/TalentSearch';
import { getReportUrl } from '../../urls';
import { ContextProvider, useFilterFormContext } from './Context';
import { filterTalents } from './Filter';
import { FilterForm } from './FilterForm';

export const PeriodReport: React.FC<{ surveyGroup: SurveyGroup }> = (props) => {
    const [, setSearchParams] = useSearchParams();
    const { currentTime } = useCurrentTimeContext();
    return (
        <ContextProvider surveyGroup={props.surveyGroup} currentTime={currentTime}>
            <Stack spacing={2}>
                <Box>
                    <Typography>人を見る</Typography>
                    <TalentSearch
                        value={null}
                        onChange={(t) => {
                            setSearchParams({ code: t?.employment.employeeCode ?? '' });
                        }}
                    />
                </Box>
                <FilterForm surveyGroup={props.surveyGroup} />
                <Report surveyGroup={props.surveyGroup} />
            </Stack>
        </ContextProvider>
    );
};

const getLastPeriod = (surveyGroup: SurveyGroup, current: SurveyPeriod): SurveyPeriod | null => {
    const periods = sortByKey(surveyGroup.periods, 'openedAt', 'desc');
    const last = periods.find((v) => v.openedAt < current.openedAt);
    return last ?? null;
};

const Report: React.FC<{
    surveyGroup: SurveyGroup;
}> = (props) => {
    const { condition } = useFilterFormContext();
    const maybeReport = useSurveyPeriodReport(props.surveyGroup.id, condition.periodId);
    const period = findById(condition.periodId, props.surveyGroup.periods);
    const lastPeriod = getLastPeriod(props.surveyGroup, period);
    const maybeLastReport = useSurveyPeriodReport(props.surveyGroup.id, lastPeriod?.id ?? null);
    const maybeSecondLastReport = useSurveyPeriodReport(
        props.surveyGroup.id,
        lastPeriod ? (getLastPeriod(props.surveyGroup, lastPeriod)?.id ?? null) : null
    );
    const maybeMemos = useSurveyMemos(period);
    return (
        <WaitLoading waitFor={[maybeMemos, maybeReport, maybeLastReport, maybeSecondLastReport]}>
            {maybeReport.data && maybeLastReport.data && maybeSecondLastReport.data && (
                <ResponsesTable
                    group={props.surveyGroup}
                    period={period}
                    report={maybeReport.data}
                    lastReport={maybeLastReport.data}
                    secondLastReport={maybeSecondLastReport.data}
                    memos={queryToArray(maybeMemos)}
                />
            )}
        </WaitLoading>
    );
};

type Response = {
    talentId: TalentId;
    respondedAt: Dayjs | null;
    [k: number]: number | string | boolean | null;
    lastMemoedAt: number;
};

type Row = Response & {
    employeeCode: string;
    name: string;
    teamName: string;
    baseTeamName: string;
};

const getScore = (questionId: number, response: Response): number => {
    const x = response[questionId];
    if (x === undefined) {
        return 0;
    }
    return typeof x === 'number' ? x : 0;
};

const sortRows = (rows: Row[], sortKey: keyof Row, direction: SortDirection) =>
    [...rows].sort((a, b) => {
        const order = direction === 'asc' ? 1 : -1;
        if (typeof sortKey === 'number') {
            const aa = a[sortKey] ?? null;
            const bb = b[sortKey] ?? null;
            if (aa === null && bb === null) {
                return 0;
            }
            if (typeof aa === 'string' || typeof bb === 'string') {
                const aaa = aa ?? '';
                const bbb = bb ?? '';
                if (aa === bb) {
                    return 0;
                }
                return aaa > bbb ? order : -order;
            }
            if (typeof aa === 'boolean' || typeof bb === 'boolean') {
                const aaa = aa ?? false;
                const bbb = bb ?? false;
                if (aa === bb) {
                    return 0;
                }
                return aaa > bbb ? order : -order;
            }
            return (getScore(sortKey, a) - getScore(sortKey, b)) * order;
        }
        return comparer(a, b, sortKey, 'employeeCode', direction);
    });

const getLastResponse = (
    report: SurveyPeriodReportResponse,
    talentId: TalentId,
    questionId: number
): number | null => {
    const res = report.responses
        .find((v) => v.talentId === talentId)
        ?.responses.find((v) => v.questionId === questionId);
    if (res === undefined) {
        return null;
    }
    return 'score' in res ? res.score : null;
};

const ResponsesTable: React.FC<{
    group: SurveyGroup;
    period: SurveyPeriod;
    report: SurveyPeriodReportResponse;
    lastReport: SurveyPeriodReportResponse;
    secondLastReport: SurveyPeriodReportResponse;
    memos: SurveyMemo[];
}> = (props) => {
    const { talents } = useTalentsContext();
    const { availableTeams } = useTeamsContext();
    const ITEMS_PER_PAGE = 100;
    const { page, setPage, condition } = useFilterFormContext();
    const requires = useStateValue<Set<number>>(new Set());

    const { sortKey, direction, SortHeader, onSort } = useTableSorter<Row>('respondedAt', 'desc');

    const questions = props.report.questions;
    const targetTalents = filterTalents(condition, talents, availableTeams);
    const targetTalentIds = new Set(targetTalents.map((v) => v.id));
    const responses = [...props.report.responses]
        .filter((v) => targetTalentIds.has(v.talentId))
        .map((res) => {
            const row: Response = {
                respondedAt: dayjs(res.respondedAt),
                lastMemoedAt:
                    props.memos
                        .find((m) => m.respondentTalentId === res.talentId)
                        ?.publishedAt.getTime() ?? 0,
                talentId: res.talentId,
            };
            for (const q of questions) {
                const v = res.responses.find((v) => v.questionId === q.id) as any;
                row[q.id] = v?.score ?? v?.message ?? v?.checked ?? null;
            }
            return row;
        });
    if (condition.notApplied === true) {
        for (const talent of targetTalents) {
            if (
                isEnrolledTalent(talent) &&
                responses.find((row) => row.talentId === talent.id) === undefined
            ) {
                responses.push({
                    respondedAt: null,
                    lastMemoedAt:
                        props.memos
                            .find((m) => m.respondentTalentId === talent.id)
                            ?.publishedAt.getTime() ?? 0,
                    talentId: talent.id,
                });
            }
        }
    }
    const rows: Row[] = generateRows(responses, talents, availableTeams).filter((row) =>
        [...requires.value].every((questionId) => {
            const res = row[questionId];
            switch (typeof res) {
                case 'string':
                    return res !== '';
                case 'boolean':
                    return res === true;
                default:
                    return false;
            }
        })
    );
    const sortedRows = sortRows(rows, sortKey, direction);
    const rowsSlice = sliceItems(sortedRows, page, ITEMS_PER_PAGE);

    const { grants } = useMeContext();
    const isOperable = grants.survey.operableSurveyGroups.includes(props.group.id);

    const [copied, setCopied] = useState(false);
    const serialize = useCallback(() => {
        return navigator.clipboard
            .writeText(serializeReport(questions, rows))
            .then(() => setCopied(true))
            .then(() =>
                setTimeout(() => {
                    setCopied(false);
                }, 800)
            );
    }, [questions, rows]);
    return (
        <Box>
            <ResponseFilter questions={questions} requires={requires} />
            {responses.length === 0 ? (
                <NoItems mt={4}>条件に合致する回答はありません</NoItems>
            ) : (
                <Box>
                    <Box my={2}>{responses.length}人が回答しました</Box>
                    <Button
                        variant="outlined"
                        onClick={serialize}
                        disabled={copied}
                        startIcon={<ContentCopyIcon fontSize="small" color="primary" />}
                        sx={{
                            p: 0.5,
                            px: 1.5,
                            width: '9rem',
                        }}
                    >
                        <Box flexGrow={1}>{copied ? 'コピーしました' : 'レポートをコピー'}</Box>
                    </Button>
                    <Pager
                        current={page}
                        setPage={setPage}
                        amount={responses.length}
                        perItems={ITEMS_PER_PAGE}
                    />
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <SortHeader sortKey="respondedAt">回答日時</SortHeader>
                                <SortHeader sortKey="employeeCode">社員番号</SortHeader>
                                <SortHeader sortKey="name">氏名</SortHeader>
                                {questions.map((question) => (
                                    <TableCell
                                        key={question.id}
                                        sx={isLikertQuestion(question) ? { px: 0.5 } : {}}
                                    >
                                        <Tooltip arrow title={question.title} placement="top">
                                            <TableSortLabel
                                                active={sortKey === question.id}
                                                direction={direction}
                                                onClick={() => onSort(question.id)}
                                            >
                                                {question.shortTitle}
                                            </TableSortLabel>
                                        </Tooltip>
                                    </TableCell>
                                ))}
                                {isOperable === true && (
                                    <SortHeader sortKey="lastMemoedAt">最新メモ</SortHeader>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rowsSlice.map((v) => (
                                <TableRow key={v.employeeCode}>
                                    <TableCell>
                                        {v.respondedAt ? v.respondedAt.format('MM/DD HH:mm') : '-'}
                                    </TableCell>
                                    <TableCell>{v.employeeCode}</TableCell>
                                    <TableCell>
                                        <PopoverTalent talent={findTalent(v.talentId, talents)}>
                                            <RouterLink
                                                variant="body2"
                                                to={getReportUrl(props.group.id, v.employeeCode)}
                                            >
                                                {v.name}
                                            </RouterLink>
                                        </PopoverTalent>
                                    </TableCell>
                                    {questions.map((question) => (
                                        <ResponseCell
                                            key={question.id}
                                            response={v[question.id]}
                                            lastResponse={getLastResponse(
                                                props.lastReport,
                                                v.talentId,
                                                question.id
                                            )}
                                            secondLastResponse={getLastResponse(
                                                props.secondLastReport,
                                                v.talentId,
                                                question.id
                                            )}
                                        />
                                    ))}
                                    {isOperable === true && (
                                        <TableCell>
                                            <Memo
                                                group={props.group}
                                                memos={props.memos.filter(
                                                    (m) => m.respondentTalentId === v.talentId
                                                )}
                                            />
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Pager
                        current={page}
                        setPage={setPage}
                        amount={responses.length}
                        perItems={ITEMS_PER_PAGE}
                    />
                </Box>
            )}
        </Box>
    );
};

const generateRows = (responses: Response[], talents: Talent[], teams: Team[]): Row[] => {
    return responses.map((v) => {
        const talent = findTalent(v.talentId, talents);
        const team = findById(talent.teamId, teams);
        const baseTeam = findBaseTeam(talent, teams);
        const row: Row = {
            ...v,
            employeeCode: talent.employment.employeeCode,
            name: fullName(talent),
            teamName: team.name,
            baseTeamName: baseTeam?.name ?? team.name,
        };
        return row;
    });
};

const serializeReport = (questions: SurveyQuestion[], rows: Row[]): string => {
    const contents = [
        [
            '回答日時',
            'ざっくり所属',
            '所属',
            '社員番号',
            '氏名',
            ...questions.map((q) => q.shortTitle),
        ],
        ...rows.map((v) => [
            v.respondedAt ? v.respondedAt.format('YYYY/MM/DD HH:mm:ss') : '-',
            v.baseTeamName,
            v.teamName,
            v.employeeCode,
            v.name,
            ...questions.map((q) => {
                const res = v[q.id];
                if (typeof res === 'boolean') {
                    return res ? 'x' : '';
                }
                return res ?? '';
            }),
        ]),
    ];
    return contents.map((v) => v.join('\t')).join('\n');
};

const Memo: React.FC<{ group: SurveyGroup; memos: SurveyMemo[] }> = (props) => {
    const { talents } = useTalentsContext();
    if (props.memos.length === 0) {
        return null;
    }
    const memo = sortByKey(props.memos, 'publishedAt', 'desc')[0];
    const author = findById(memo.authorTalentId, talents);
    const headline = memo.content.split('\n')[0];
    const talent = findById(memo.respondentTalentId, talents);
    const period = findById(memo.surveyPeriodId, props.group.periods);
    return (
        <Tooltip
            components={{ Tooltip: Box }}
            placement="top-start"
            title={
                <Card>
                    <CardContent>
                        <FlexBox gap={1}>
                            <TalentAvatar talent={author} size="small" />
                            <Typography variant="body2">{fullName(author)}</Typography>
                            <Typography variant="body2">
                                {dayjs(memo.publishedAt).format('YYYY-MM-DD HH:mm:ss')}
                            </Typography>
                        </FlexBox>
                        <Box my={1}>
                            <Divider />
                        </Box>
                        <Markdown source={memo.content} variant="body2" />
                        {props.memos.length > 1 && (
                            <Box textAlign="right">
                                <RouterLink
                                    variant="body2"
                                    to={getReportUrl(
                                        props.group.id,
                                        talent.employment.employeeCode,
                                        period.name
                                    )}
                                >
                                    他{props.memos.length - 1}件を見る
                                </RouterLink>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            }
        >
            <Typography variant="body2">{headline}</Typography>
        </Tooltip>
    );
};

const ResponseFilter: React.FC<{
    questions: SurveyQuestion[];
    requires: StateValue<Set<number>>;
}> = (props) => {
    const questions = props.questions.filter((q) => !isLikertQuestion(q));
    if (questions.length === 0) {
        return null;
    }
    return (
        <Box mt={1}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FlexBox>
                        <FilterAltIcon />
                        <Box ml={0.5}>記述項目への回答の有無で絞り込む</Box>
                    </FlexBox>
                </AccordionSummary>
                <AccordionDetails>
                    {questions.map((q) => (
                        <FlexBox key={q.id}>
                            <Box ml={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props.requires.value.has(q.id)}
                                            onChange={(e) => {
                                                props.requires.setValue((prev) => {
                                                    const x = new Set(prev);
                                                    if (e.target.checked) {
                                                        x.add(q.id);
                                                    } else {
                                                        x.delete(q.id);
                                                    }
                                                    return x;
                                                });
                                            }}
                                            name={q.shortTitle}
                                            size="small"
                                        />
                                    }
                                    label={
                                        <Typography variant="body2">
                                            {q.shortTitle} : {q.title}
                                        </Typography>
                                    }
                                />
                            </Box>
                        </FlexBox>
                    ))}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

const ResponseCell: React.FC<{
    response: number | string | boolean | null;
    lastResponse: number | null;
    secondLastResponse: number | null;
}> = (props) => {
    switch (typeof props.response) {
        case 'number':
            return (
                <TableCell align="right" sx={{ pl: 0, pr: 2.5 }}>
                    {props.secondLastResponse !== null && (
                        <Typography display="inline" variant="caption" color="textDisabled">
                            {props.secondLastResponse}
                            {props.lastResponse === null ? ' - . - ' : ' - '}
                        </Typography>
                    )}
                    {props.lastResponse !== null && (
                        <Typography display="inline" variant="caption" color="textDisabled">
                            {props.lastResponse}
                            {' - '}
                        </Typography>
                    )}
                    {props.response}
                </TableCell>
            );
        case 'string':
            return <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{props.response}</TableCell>;
        case 'boolean':
            return <TableCell>{props.response && <CheckBoxIcon fontSize="small" />}</TableCell>;
        default:
            return <TableCell />;
    }
};
