import { Tooltip, Typography, useTheme } from '@mui/material';
import { Talent } from '@spec/Talent';
import React from 'react';
import { FlexBox } from '../FlexBox';
import { ExternalLink } from '../RouterLink';

export const SlackName: React.FC<{ talent: Talent; enableLink?: boolean }> = (props) => {
    const name = props.talent.slackName || '-';
    const theme = useTheme();
    if (props.talent.slackId === null) {
        return (
            <FlexBox flexWrap="nowrap">
                <img
                    src={`/images/Slack_Mark_Monochrome_${theme.palette.mode === 'dark' ? 'White' : 'Black'}.svg`}
                    width="20"
                    height="20"
                    style={{ marginLeft: '-4px' }}
                />
                <Typography ml={0.5} variant="body2" color="textDisabled">
                    未連携
                </Typography>
            </FlexBox>
        );
    }
    return (
        <FlexBox flexWrap="nowrap">
            <img
                src="/images/Slack_Mark.svg"
                width="20"
                height="20"
                style={{ marginLeft: '-4px' }}
            />
            {props.enableLink ? (
                <Tooltip arrow title="Slackでプロフィールを開く" placement="top">
                    <div>
                        <ExternalLink
                            variant="body2"
                            href={`https://cartaholdings.slack.com/team/${props.talent.slackId}`}
                        >
                            {name}
                        </ExternalLink>
                    </div>
                </Tooltip>
            ) : (
                <Typography variant="body2">{name}</Typography>
            )}
        </FlexBox>
    );
};
