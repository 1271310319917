export type ApplicablePath = `@${string}` | 'article';

const RESERVED_ARTICLE_PATH = 'article';

/**
 * Return the path is applicable for the article page
 */
export const isApplicablePath = (path?: string): path is ApplicablePath => {
    if (path === undefined) {
        return false;
    }
    if (path === RESERVED_ARTICLE_PATH) {
        return true;
    }
    return /^@.+$/.test(path);
};

/**
 * Return the path should be redirected to the proper article page
 */
export const shouldRedirect = (authorHitonowaId: string, path: ApplicablePath): boolean => {
    if (path === RESERVED_ARTICLE_PATH) {
        return false;
    }
    return path !== `@${authorHitonowaId}`;
};

/**
 * Return the URL is note article page or not
 */
export const isNoteUrl = (url: string, tenant: string) =>
    url.startsWith(`${window.location.origin}/${tenant}/notes/`);
